import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  Assessment,
  AssessmentResultInterface,
  FeedbackQuestions,
  SurveyAssessmentTakerDetails,
  SurveyResponses,
} from '../../Interfaces/Types/reportServiceInterface';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import {
  AssessmentResponses,
  AssessmentTakerApiResponse,
  FlagQuestion,
  QuestionsResults,
  ResultReturnBody,
  TestSubmission,
  TestTakingInformation,
  TestTakingTrackingData,
} from '../../Interfaces/Types/testTakerInterface';
@Injectable({
  providedIn: 'root',
})
export class TestTakingService {
  private accumulatedTimeTaken: number = 0;
  private id: string = '';
  private testTakingUrl = `${environment.apiBaseUrl}/test-taking/assessment`;
  public takerUrl: string = '';
  private proctoringInfo: string[] = [];
  public testTakerApiData$: BehaviorSubject<TestTakingTrackingData | null> =
    new BehaviorSubject<TestTakingTrackingData | null>(null);

  selectedAnswerChoices: string[] = [];
  inProgressReturnedData: TestTakingInformation | undefined;
  inViewReturnedData: TestTakingTrackingData | undefined;
  receivedAssessmentInformation: TestTakingInformation | undefined;
  trackData: TestSubmission[] = [];
  public candidateAnswers: string[] = [];
  public candidateEssayAnswers: QuestionsResults[] = [];
  assessmentData: AssessmentResponses = {
    startTime: '',
    finishTime: '',
    testResults: [],
    screenMonitoring: [],
    candidateMonitoring: [],
    isAutoSubmission: false,
  };
  public autoSubmit: boolean = false;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    const user = this.authService.getLoginUser();

    if (!user) {
      this.route.queryParams.subscribe((params) => {
        this.id = params['organizationId'];
      });
    } else {
      this.id = user.organizationId;
    }
  }
  startCountdown(initialMinutes: number) {
    let remainingTime = initialMinutes;
    const countdownInterval = setInterval(() => {
      if (remainingTime > 0) {
        remainingTime--;
        localStorage.setItem('remainingMinutes', remainingTime.toString());
      } else {
        clearInterval(countdownInterval);
      }
    }, 60000);
  }
  getAssessmentResult(assId: string) {
    const url = `${environment.apiBaseUrl}/test-service/assessment/${this.id}/${assId}/assessmentLinks`;
    return this.http.get<{ data: Assessment[] }>(`${url}`);
  }

  getAssessmentLinkResults(assId: string, search: string, status?: string) {
    const url = `${environment.apiBaseUrl}/test-service/assessment/${this.id}/${assId}/assessmentLinks`;
    if (!status) {
      return this.http.get<AssessmentResultInterface>(
        `${url}?candidate=${search}`
      );
    }
    return this.http.get<AssessmentResultInterface>(
      `${url}?candidate=${search}&status=${status}`
    );
  }

  getTestTakerImages(id: string) {
    return this.http.get<{
      success: boolean;
      data: {
        assessmentTakerCaptureShots: {
          id: number;
          imageURL: string;
          createdAt: string;
          isViolationShot: boolean;
        }[];
      };
    }>(`${this.testTakingUrl}/${id}/capture-shots
    `);
  }

  getScreenImages(id: string) {
    return this.http.get<{
      success: boolean;
      data: {
        assessmentScreenShots: {
          id: number;
          imageURL: string;
          createdAt: string;
          isViolationShot: boolean;
        }[];
      };
    }>(`${this.testTakingUrl}/${id}/screen-shots
    `);
  }

  getAssessmentInformation(testTakerId: string, assessmentInformation = {}) {
    return this.http.post<{ success: boolean; data: TestTakingInformation }>(
      `${environment.apiBaseUrl}/test-taking/assessment/${testTakerId}/assessment-in-progress`,
      assessmentInformation
    );
  }

  getIdentityImages(id: string) {
    return this.http.get<{
      success: boolean;
      data: {
        identity: {
          linkId: string;
          linkHead: string;
        };
      };
    }>(`${this.testTakingUrl}/${id}/getIdentity
    `);
  }

  getTakerAssessment(testTakerId: string) {
    return this.http.get<AssessmentTakerApiResponse>(
      `${environment.apiBaseUrl}/test-taking/assessment/${testTakerId}/link-status`
    );
  }
  setProctoringInfo(data: string[]) {
    this.proctoringInfo = data;
  }

  getProctoringInfo() {
    return this.proctoringInfo;
  }

  setTestTakerApiData(data: TestTakingTrackingData) {
    this.testTakerApiData$.next(data);
  }

  addTimeTaken(timeTaken: number) {
    this.accumulatedTimeTaken += timeTaken;
  }

  getTestTakerIdFromParam() {
    const urlObject = new URL(window.location.href);
    const queryParams = urlObject.searchParams;
    return queryParams.get('testTakerId');
  }

  setTestTakerQueryParam(revisited: string) {
    return {
      queryParams: {
        testTakerId: this.getTestTakerIdFromParam(),
        state: revisited,
      },
    };
  }
  setUrl(url: string) {
    this.takerUrl = url;
  }
  getUrl() {
    return this.takerUrl;
  }
  getTestStateQueryParam() {
    const urlObject = new URL(window.location.href);
    const queryParams = urlObject.searchParams;
    return queryParams.get('state');
  }

  addTestResult(result: QuestionsResults, testId: string) {
    this.assessmentData.testResults.forEach((testResult) => {
      if (testId === testResult.testId) {
        const exists = testResult.questionResults.some(
          (questionResult) => questionResult.questionId === result.questionId
        );

        if (exists) {
          // Question with the same Id exists, replace it
          const existingIndex = testResult.questionResults.findIndex(
            (questionResult) => result.questionId === questionResult.questionId
          );
          testResult.questionResults[existingIndex] = result;
        } else {
          // Question with the same Id does not exist, push it
          testResult.questionResults.push(result);
        }
      }
    });
    localStorage.setItem(
      'assessmentResults',
      JSON.stringify(this.assessmentData)
    );
  }

  postAssessmentSubmit() {
    return this.http.post(
      `${this.testTakingUrl}/${this.inProgressReturnedData?.id}/submit`,
      { ...this.assessmentData, isAutoSubmission: this.autoSubmit }
    );
  }
  postSectionSubmit() {
    return this.http.post<{ success: boolean; data: { message: string } }>(
      `${this.testTakingUrl}/${this.inProgressReturnedData?.id}/submit-test`,
      this.assessmentData
    );
  }

  getAccumulatedTimeTaken(): number {
    return this.accumulatedTimeTaken;
  }

  getCandidatesResult(assessmentTakerId: string) {
    return this.http.get<ResultReturnBody>(
      `${this.testTakingUrl}/${assessmentTakerId}/assessment-result`
    );
  }

  flagQuestion(flagQuestion: FlagQuestion) {
    return this.http.post(
      `${this.testTakingUrl}/${this.inProgressReturnedData?.id}/question-flag`,
      flagQuestion
    );
  }

  unflagQuestion(unflagQuestion: FlagQuestion) {
    return this.http.post<{
      success: boolean;
      data: { message: string };
    }>(
      `${this.testTakingUrl}/${this.inProgressReturnedData?.id}/question-unflag`,
      unflagQuestion
    );
  }

  getSurvey(organisationId: string, takerId: string) {
    return this.http.get<{
      success: boolean;
      data: {
        survey: FeedbackQuestions[];
        assessmentTaker: SurveyAssessmentTakerDetails;
      };
    }>(
      `${environment.apiBaseUrl}/test-service/assessment/${organisationId}/${takerId}/get-survey-statements`
    );
  }

  publishSurvey(data: SurveyResponses, takerId: string) {
    return this.http.post(`${this.testTakingUrl}/${takerId}/survey`, data);
  }

  postSavedQuestionsWithAnswersAsDraft() {
    if (this.assessmentData.testResults[0] === undefined) return;
    const { testResults, candidateMonitoring, screenMonitoring } =
      this.assessmentData;
    return this.http
      .post(
        `${environment.apiBaseUrl}/test-taking/assessment/${this.inProgressReturnedData?.id}/draft-questions`,
        { testResults, candidateMonitoring, screenMonitoring }
      )
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              error.status === 0 &&
              new Error('Please check your internet connection')
          )
        )
      );
  }

  setCandidateAnswers(answers: string[]) {
    this.candidateAnswers = answers;
  }

  getCandidateAnswers() {
    return this.candidateAnswers;
  }

  setCandidateEssayAnswers(answers: QuestionsResults[]) {
    this.candidateEssayAnswers = answers;
  }

  getCandidateEssayAnswers() {
    return this.candidateEssayAnswers;
  }
}
